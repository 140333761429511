export function copyToClipboard(htmlStr, withNotification = false) {
    function preparePlainText(html) {
        // Replace <br> and <br/> tags with newline characters
        html = html.replace(/<br\s*\/?>/gi, "\n");

        // Wrap the contents of <p> tags with newline characters
        html = html.replace(/<\/p>/gi, "\n").replace(/<p[^>]*>/gi, "\n");

        // Remove all other HTML tags
        return html.replace(/<\/?[^>]+(>|$)/g, "");
    }

    function handler(event) {
        const plainText = preparePlainText(htmlStr);

        event.clipboardData.setData('text/html', htmlStr);
        event.clipboardData.setData('text/plain', plainText);
        event.preventDefault();
    }
    document.addEventListener('copy', handler);
    document.execCommand('copy');
    document.removeEventListener('copy', handler);

    if (withNotification) {
        new FilamentNotification()
            .title('Copied!')
            .body('The content has been copied to your clipboard.')
            .icon('heroicon-o-clipboard-document-check')
            .success()
            .send();
    }
}
