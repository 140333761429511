export function dropdownSearch(customLimit = 4) {
    return {
        search: '',
        limit: customLimit,
        isVisible(item, index) {
            if (this.search === '') {
                return index < this.limit;
            }
            return item.toLowerCase().includes(this.search.toLowerCase());
        }
    };
}
