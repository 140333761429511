export function pdfViewer() {
    let pdfDoc = null;
    let pageRendering = false;
    let canvas = null;
    let ctx = null;
    let container = null;
    let textLayerDiv = null;

    const stopWords = new Set([
        'a', 'an', 'and', 'are', 'as', 'at', 'be', 'by', 'for', 'from', 'has', 'he',
        'in', 'is', 'it', 'its', 'of', 'on', 'that', 'the', 'to', 'was', 'were', 'will', 'with',
        'they', 'their', 'them', 'this', 'those', 'or', 'which', 'who', 'whom', 'whose',
    ]);

    function levenshteinDistance(a, b) {
        if (a.length === 0) return b.length;
        if (b.length === 0) return a.length;

        const matrix = [];

        for (let i = 0; i <= b.length; i++) {
            matrix[i] = [i];
        }

        for (let j = 0; j <= a.length; j++) {
            matrix[0][j] = j;
        }

        for (let i = 1; i <= b.length; i++) {
            for (let j = 1; j <= a.length; j++) {
                if (b.charAt(i - 1) === a.charAt(j - 1)) {
                    matrix[i][j] = matrix[i - 1][j - 1];
                } else {
                    matrix[i][j] = Math.min(
                        matrix[i - 1][j - 1] + 1,
                        matrix[i][j - 1] + 1,
                        matrix[i - 1][j] + 1
                    );
                }
            }
        }

        return matrix[b.length][a.length];
    }

    return {
        currentPage: 1,
        pageCount: 0,
        loading: false,
        error: null,
        pdfLoaded: false,
        scale: 1.5,
        pdfUrl: null,
        quote: '',

        init() {
            canvas = document.getElementById('pdf-canvas');
            ctx = canvas.getContext('2d');
            container = document.getElementById('pdf-container');
            textLayerDiv = document.getElementById('text-layer');

            this.$watch('$wire.path', (path) => {
                if (path) {
                    this.pdfUrl = path;
                    this.loadPdf(path).catch(err => {
                        console.error('Error in loadPdf:', err);
                        this.error = `Error loading PDF: ${err.message}`;
                    });
                }
            });

            this.$watch('$wire.quote', (quote) => {
                this.quote = quote;
                if (this.pdfLoaded) {
                    this.searchAndNavigateToQuote().catch(err => {
                        console.error('Error in searchAndNavigateToQuote:', err);
                        this.error = `Error searching quote: ${err.message}`;
                    });
                }
            });

            if (this.$wire.path) {
                this.loadPdf(this.$wire.path).catch(err => {
                    console.error('Error in initial loadPdf:', err);
                    this.error = `Error loading initial PDF: ${err.message}`;
                });
            }

            if (this.$wire.quote) {
                this.quote = this.$wire.quote;
            }

            window.addEventListener('resize', () => {
                if (this.pdfLoaded) {
                    this.renderPage(this.currentPage).catch(err => {
                        console.error('Error in resize renderPage:', err);
                        this.error = `Error re-rendering page: ${err.message}`;
                    });
                }
            });
        },

        async loadPdf(url) {
            this.loading = true;
            this.error = null;
            this.pdfLoaded = false;

            try {
                const loadingTask = pdfjsLib.getDocument(url);
                pdfDoc = await loadingTask.promise;
                this.pageCount = pdfDoc.numPages;
                this.pdfLoaded = true;

                if (this.quote) {
                    await this.searchAndNavigateToQuote();
                } else {
                    await this.renderPage(1);
                }
            } catch (err) {
                console.error('Error loading PDF:', err);
                this.error = `Error loading PDF: ${err.message}`;
            } finally {
                this.loading = false;
            }
        },

        async renderPage(num) {
            if (pageRendering) {
                return;
            }
            pageRendering = true;

            try {
                const page = await pdfDoc.getPage(num);
                const viewport = page.getViewport({ scale: this.scale });

                const containerWidth = container.clientWidth;
                const scaleFactor = containerWidth / viewport.width;
                const adjustedViewport = page.getViewport({ scale: this.scale * scaleFactor });

                canvas.height = adjustedViewport.height;
                canvas.width = adjustedViewport.width;

                const renderContext = {
                    canvasContext: ctx,
                    viewport: adjustedViewport
                };

                await page.render(renderContext).promise;

                while (textLayerDiv.firstChild) {
                    textLayerDiv.removeChild(textLayerDiv.firstChild);
                }

                const textContent = await page.getTextContent();
                const textLayer = document.createElement('div');
                textLayer.className = 'textLayer';

                await pdfjsLib.renderTextLayer({
                    textContent,
                    container: textLayer,
                    viewport: adjustedViewport,
                    textDivs: []
                }).promise;

                textLayerDiv.appendChild(textLayer);
                this.highlightQuote(this.lastHighlightedText);

                this.currentPage = num;
            } catch (err) {
                console.error('Error rendering page:', err);
                this.error = `Error rendering page: ${err.message}`;
            } finally {
                pageRendering = false;
            }
        },

        highlightQuote(bestMatchText) {
            if (this.quote && textLayerDiv) {
                const instance = new Mark(textLayerDiv);

                if (bestMatchText) {
                    instance.mark(bestMatchText, {
                        accuracy: "partially",
                        separateWordSearch: false,
                        acrossElements: true,
                        caseSensitive: false,
                        ignoreJoiners: true,
                        ignorePunctuation: [",", ".", ";", ":", ")", "(", "?", "!"]
                    });
                }

                const words = this.quote.toLowerCase().split(/\s+/);
                for (let i = 0; i < words.length - 1; i++) {
                    const numberOfWordsToHighlight = 4;
                    const phrase = words.slice(i, i + numberOfWordsToHighlight).join(' ');

                    // Highlight phrases with missing spaces by concatenating them
                    const concatenatedPhrase = words[i] + words[i + 1];

                    if (!phrase.split(' ').every(word => stopWords.has(word))) {
                        instance.mark(phrase, {
                            accuracy: "partially",
                            separateWordSearch: false,
                            acrossElements: true,
                            caseSensitive: false,
                            ignoreJoiners: true,
                            ignorePunctuation: [",", ".", ";", ":", ")", "(", "?", "!"]
                        });
                        instance.mark(concatenatedPhrase, {
                            accuracy: "partially",
                            separateWordSearch: false,
                            acrossElements: true,
                            caseSensitive: false,
                            ignoreJoiners: true,
                            ignorePunctuation: [",", ".", ";", ":", ")", "(", "?", "!"]
                        });
                    }
                }
            }
        },

        findBestMatch(text, quote) {
            const quoteWords = quote.toLowerCase().split(/\s+/);
            const textWords = text.toLowerCase().split(/\s+/);
            let bestMatch = { score: 0, start: 0, length: 0 };

            // Concatenate words to form potential matches that might have missing spaces
            for (let i = 0; i < textWords.length - Math.floor(quoteWords.length / 2); i++) {
                let matchScore = 0;
                let matchLength = 0;
                let concatenatedText = "";

                for (let j = 0; j < quoteWords.length && i + j < textWords.length; j++) {
                    concatenatedText += textWords[i + j];
                    const distance = levenshteinDistance(quoteWords[j], concatenatedText);

                    if (distance <= 3) {  // Allowing up to 3 character differences per word
                        matchScore++;
                        matchLength += concatenatedText.length;
                    } else {
                        // Reset if the concatenated text doesn't match
                        concatenatedText = textWords[i + j];
                    }
                }

                // Adjust scoring to favor longer matches
                const adjustedScore = matchScore * (matchLength / quote.length);

                if (adjustedScore > bestMatch.score) {
                    bestMatch = {
                        score: adjustedScore,
                        start: text.indexOf(textWords[i]),
                        length: matchLength
                    };
                }
            }

            return bestMatch;
        },

        async searchAndNavigateToQuote() {
            if (!this.quote || !pdfDoc) return;

            let globalBestMatch = { page: 1, score: 0, text: '' };
            for (let i = 1; i <= this.pageCount; i++) {
                try {
                    const page = await pdfDoc.getPage(i);
                    const textContent = await page.getTextContent();
                    const pageText = textContent.items.map(item => item.str).join(' ');

                    const bestMatch = this.findBestMatch(pageText, this.quote);
                    if (bestMatch.score > globalBestMatch.score) {
                        globalBestMatch = {
                            page: i,
                            score: bestMatch.score,
                            text: pageText.substr(bestMatch.start, bestMatch.length)
                        };
                    }
                } catch (err) {
                    console.error(`Error processing page ${i}:`, err);
                }
            }

            await this.renderPage(globalBestMatch.page);
            this.lastHighlightedText = globalBestMatch.text;
            this.highlightQuote(globalBestMatch.text);
        },

        prevPage() {
            if (this.currentPage <= 1) return;
            this.renderPage(this.currentPage - 1).catch(err => {
                console.error('Error in prevPage:', err);
                this.error = `Error navigating to previous page: ${err.message}`;
            });
        },

        nextPage() {
            if (this.currentPage >= this.pageCount) return;
            this.renderPage(this.currentPage + 1).catch(err => {
                console.error('Error in nextPage:', err);
                this.error = `Error navigating to next page: ${err.message}`;
            });
        },

        closeViewer() {
            this.$wire.show = false;
        },
    };
}
