import Mention from '@tiptap/extension-mention';
import suggestion from './suggestions';


export default Mention.configure({
    HTMLAttributes: {
      class: 'mention tre',
    },
    suggestion,
  });
