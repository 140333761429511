import tippy from 'tippy.js';
import getContent from './getContent';

let _query = null;

export default {
  items: ({ query }) => {
    _query = query;

    var suggestions = JSON.parse(document.getElementById('suggestions').getAttribute('data-array')); // Passed from the component to blade
    return suggestions
      .filter((item) => item.toLowerCase().startsWith(query.toLowerCase()))
      .slice(0, 5);
  },
  // Override command property
  command: ({ editor, range, props }) => {
    let deleteFrom = range.to + 1;
    let deleteTo = _query.length + deleteFrom;

    editor
      .chain()
      .focus()
      .insertContentAt(range, [
        {
          type: 'mention',
          attrs: props,
        },
        {
          type: 'text',
          text: ' ',
        },
      ])
      .deleteRange({ from: deleteFrom, to: deleteTo })
      .run();

    window.getSelection()?.collapseToEnd();
  },
  render: () => {
    let component;
    let popup;

    return {
      onStart: (props) => {
        component = getContent(props);
        if (!props.clientRect) {
          return;
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: (reference) => component,
          allowHTML: true,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
          theme: 'dropdown',
          arrow: false,
        });
      },

      onUpdate(props) {
        const event = new CustomEvent('update-props', { detail: props });
        window.dispatchEvent(event);
        if (!props.clientRect) {
          return;
        }
      },

      onKeyDown(props) {
        const event = new CustomEvent('suggestion-keydown', { detail: props });
        window.dispatchEvent(event);
        if (['ArrowUp', 'ArrowDown', 'Enter'].includes(props.event.key)) {
          return true;
        }
        return false;
      },

      onExit() {
        popup[0].destroy();
      },
    };
  },
};
