export default function fetchCount(url) {
    return {
        count: 0,
        url: url,
        fetchData() {
            fetch(this.url)
                .then(response => response.json())
                .then(data => this.count = data.count);
        },
        init() {
            this.fetchData();
        }
    };
}
